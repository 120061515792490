<template>
    <div>
        <div class="mb-3">
            <label for="username" class="form-label"> Email </label>
            <input type="text" class="form-control" v-model="userCopy.username" id="username" />
        </div>
        <div class="mb-3">
            <label class="form-label">
                Numéros de téléphone
                <button
                    class="btn btn-sm btn-primary ms-3"
                    @click="userCopy.userInfos.phoneNumbers.push({ value: '', isNew: true })"
                >
                    Ajouter
                </button>
            </label>
            <div
                v-for="(phoneNumber, i) in userCopy.userInfos.phoneNumbers"
                :key="'phoneNumber-' + i"
                class="input-group mb-3"
            >
                <input
                    type="text"
                    class="form-control"
                    :class="{
                        'border-warning':
                            !user.userInfos.phoneNumbers[i] ||
                            phoneNumber.value !== user.userInfos.phoneNumbers[i].value,
                    }"
                    placeholder="numéro de téléphone"
                    v-model="phoneNumber.value"
                />
                <button
                    class="btn btn-outline-danger"
                    type="button"
                    @click="userCopy.userInfos.phoneNumbers.splice(i, 1)"
                >
                    Supprimer
                </button>
            </div>
        </div>
        <div class="mb-3">
            <label for="lastName" class="form-label">Nom</label>
            <input
                type="text"
                class="form-control"
                v-model="userCopy.userInfos.lastName"
                :class="{ 'border-warning': userCopy.userInfos.lastName !== user.userInfos.lastName }"
                id="lastName"
            />
        </div>
        <div class="mb-3">
            <label for="firstName" class="form-label">Prénom</label>
            <input
                type="text"
                class="form-control"
                :class="{ 'border-warning': userCopy.userInfos.firstName !== user.userInfos.firstName }"
                v-model="userCopy.userInfos.firstName"
                id="firstName"
            />
        </div>
        <div v-if="$store.getters['login/isAdminFunc']">
            <div class="mb-3">
                <label for="firstName" class="form-label">Profil :</label>
                <div>
                    <input type="radio" id="worker" value="worker" v-model="userCopy.profil" name="profil" />
                    <label for="worker">&nbsp;Agent ST</label>
                    <div>
                        <input type="radio" id="admin_st" value="admin_st" v-model="userCopy.profil" name="profil" />
                        <label for="admin_st">&nbsp;Manager ST</label>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label for="firstName" class="form-label">Statut :</label>
                <div>
                    <input type="radio" id="Actif" value="Actif" v-model="userCopy.status" name="status" />
                    <label for="Actif">&nbsp;Actif</label>
                    <div>
                        <input type="radio" id="Inactif" value="Inactif" v-model="userCopy.status" name="status" />
                        <label for="Inactif">&nbsp;Inactif</label>
                    </div>
                    <div>
                        <input type="radio" id="Erreur" value="Erreur" v-model="userCopy.status" name="status" />
                        <label for="Erreur">&nbsp;Erreur</label>
                    </div>
                </div>
            </div>
            <div class="mb-3 d-flex justify-content-between">
                <div class="col-lg-10">
                    <multiselect
                        :select-group-label="'Sociétés'"
                        :options="companies"
                        :group-select="false"
                        :multiple="true"
                        v-model="userCopy.userInfos.agencies"
                        group-values="agencies"
                        group-label="company"
                        placeholder="Nom de l'agence sous-traitante"
                        selectLabel="Cliquez pour sélectionner l'agence sous-traitante"
                        deselectLabel=""
                        label="name"
                        track-by="id"
                    >
                    </multiselect>
                </div>
            </div>
        </div>
        <div v-if="userHasDataChanged" class="text-end mt-5">
            <div class="btn-group" role="group">
                <div v-if="$store.getters['login/isAdminFunc']">
                    <button
                        v-if="!userRecordInProgress"
                        class="btn btn-primary"
                        @click="userCopy = Object.assign({}, JSON.parse(JSON.stringify(user)))"
                    >
                        {{ cancelText }}
                    </button>
                    &nbsp;
                    <button class="btn btn-warning" @click="userRecord">
                        {{ recordText }}
                        <div v-if="userRecordInProgress" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
                    </button>
                </div>
            </div>
        </div>
        <blank-modal ref="modalCompany" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend"></legend>
            </template>
            <template #modalBody> </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="onClickOkAgency">Ok</button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
export default {
    name: 'UserSubcontractor',
    props: {
        user: {
            required: false,
            default: () => ({
                id: null,
                username: '',
                roles: [],
                userInfos: {
                    firstName: '',
                    lastName: '',
                    phoneNumbers: [],
                    company: [],
                    agencies: [],
                },
                accountType: '',
            }),
        },
        userRecordMethod: {
            type: Function,
            required: false,
            default: () => Promise.resolve(),
        },
        recordText: {
            type: String,
            required: false,
            default: () => 'Enregistrer les modifications',
        },
        cancelText: {
            type: String,
            required: false,
            default: () => 'Annuler',
        },
        showPasswordFields: {
            required: false,
            type: Boolean,
            default: () => false,
        },
        showRolesSelection: {
            required: false,
            type: Boolean,
            default: () => false,
        },
        usernameEditable: {
            required: false,
            type: Boolean,
            default: () => false,
        },
        edit: {
            required: false,
            type: Boolean,
            default: () => false,
        },
        parameter: {
            required: false,
            type: Boolean,
            default: () => false,
        },
    },
    data: () => ({
        profil: null,
        statut: null,
        userCopy: {},
        companies: [],
        companiesFilteredIntermsOf: [],
        agencySelected: [],
        agencies: [],
        isAdmin: false,
        userRecordInProgress: false,
        rolesList: {
            admin: 'Administrateur',
        },
        tempAgencies: [],
    }),
    watch: {
        user: {
            deep: true,
            handler() {
                this.copyUser()
            },
        },
        agencySelected(val) {
            this.userCopy.userInfos.agency = val
        },
        userCopy: {
            deep: true,
            handler() {
                console.log('usercopy has changed')
            },
        },
    },
    methods: {
        onClickOkAgency() {
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalCompany.$el).hide()
            this.agencies.push(...this.tempAgencies.filter((it) => !this.agencies.includes(it)))
            this.userCopy.userInfos.agencies = this.agencies.slice()
            this.tempAgencies = []
        },
        copyUser() {
            this.userCopy = Object.assign(
                {
                    profil: this.user.roles?.includes('admin') ? 'admin_st' : 'worker',
                    status: this.user?.userStatus?.value,
                },
                JSON.parse(JSON.stringify(this.user)),
            )
            // this.agencies = this.user.userInfos.agencies.slice()
        },
        userRecord() {
            this.userRecordInProgress = true

            const payload = JSON.parse(JSON.stringify(this.userCopy))
            payload.userInfos.agency = this.userCopy.userInfos.agencies.map((c) => c.id)
            delete payload.userInfos.agencies
            this.$http
                .createUserWorker(payload, this.edit, this.userCopy.id)
                .then(() => {
                    this.copyUser()
                    this.$router.push({ name: 'users' })
                })
                .catch((e) => {
                    this.userRecordInProgress = false
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        company() {
            this.$http
                .companyByAccount(0)
                .then((res) => {
                    let companies = res.map((item) => {
                        return {
                            name: item.name,
                            id: item.id,
                            agencies: item.agencies,
                        }
                    })
                    for (var i = 0; i < companies.length; i++) {
                        var currentCompany = companies[i]
                        var agencies = []
                        for (var j = 0; j < currentCompany.agencies.length; j++) {
                            var currentAgency = currentCompany.agencies[j]
                            agencies.push({ name: currentAgency.name, id: currentAgency.id })
                        }
                        this.companies.push({
                            company: currentCompany.name,
                            agencies: agencies,
                        })
                    }
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
    },
    computed: {
        userHasDataChanged() {
            console.log(
                'userHasDatChanged',
                JSON.stringify(this.user) !== JSON.stringify(this.userCopy),
                this.user,
                this.userCopy,
            )
            return JSON.stringify(this.user) !== JSON.stringify(this.userCopy)
        },
    },
    created() {
        this.copyUser()
    },
    mounted() {
        this.company()
    },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.content {
    min-height: 200px;
}
.multiselect__option:not(.multiselect__option--group) {
    padding-left: 20px;
}
</style>
